/*
 * @Author: 张小闹
 * @Date: 2020-08-01 10:50:52
 * @LastEditors: 张小闹
 * @LastEditTime: 2020-08-01 14:16:08
 * @FilePath: \newVue-aftersale\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import iView from 'iview'
import 'iview/dist/styles/iview.css'
import global from './global/common'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/index.css'  // 清除默认样式
import './assets/css/icon.css'
import './assets/css/theme.css'
import './assets/css/common.css'
import './assets/css/detail.css'
import axios from '@/utils/axios'
import formatter from '@/utils/formatter'
import VueCookies from 'vue-cookies'
import './utils/download'
// import VueUeditorWrap from 'vue-ueditor-wrap'

// import Loading from 'pages/comp/loading'
// import moment from 'moment'

Vue.use(iView);
Vue.use(VueCookies)
Vue.prototype.$global = global
Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'mini', zIndex: 3000 });
Vue.prototype.axios = axios
Vue.prototype.formatter = formatter
// Vue.component('vue-ueditor-wrap', VueUeditorWrap)
new Vue({
  router,
  store,
  axios,
  ...app,
  render: h => h(App)
}).$mount('#app')
