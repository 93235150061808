import Vue from 'vue';
import {
    localSave,
    getTabNavList
} from '../../libs/util'
const app = {
    state: {
        businessManagementList: [{
            title: '申请合作',
            path: '/',
            name: 'home_index'
        }],
        maxLeft: 0,
        lastTag: 0,
        manufacturerState: true,
        salesState: true, //true为通用模式
        routerPathList: ["/",'master','error'], // 路由path列表,
        allRouteList: [],
        headers: {
            'Authorization': window.sessionStorage.getItem('token')
        },
        activeTabs: '/',
        activeMenu: 0,
        headHopData:''
    },
    mutations: {
        increateTag(state, tagObj) {
            // state.businessManagementList.push(tagObj)
            if (JSON.stringify(state.businessManagementList).indexOf(JSON.stringify(tagObj)) == -1 && tagObj.path != '/') {
                state.businessManagementList.push(tagObj)
            }
            // if (!Util.oneOf(tagObj.name, state.dontCache)) {
            //     state.cachePage.push(tagObj.name);
            //     localStorage.cachePage = JSON.stringify(state.cachePage);
            // }
            // state.businessManagementList.push(tagObj);
            // localStorage.businessManagementList = JSON.stringify(state.businessManagementList);
        },
        //添加add
        addTab(state, { route, type = 'unshift' }) {
            // console.log(state,route,type,21321312)
            let { path, name, query } = route
            let { title, active } = route.meta
            let tabs = { path, name, query, title, active }

            let data = state.businessManagementList.filter(res => {
                return res.path == tabs.path;
            })
            // console.log(data,route.query,2222)
            if (data.length == 0) {
                state.businessManagementList.push(tabs)
            } else {
                state.businessManagementList.map(res => res.name == data[0].name ? res.query = route.query : '')
            }
        },
        //获取query
        setQuery(state, route) {
            let data = state.businessManagementList.filter(res => {
                res.path == route.path ? (res.active = true) : (res.active = false)
                return res.path == route.path;
            })
            state.activeTabs = data[0].path
            return data[0]
        },
        setTabNavList(state) {
            let tabList = getTabNavList()
            tabList.map(res => {
                res.active ? state.activeTabs = res.path : '/'
            })
            if (tabList.length != 0) {
                state.businessManagementList = tabList
            }
        },
        mapRouteList(state, list) {
            state.routerPathList = list
        },
        allRouteList(state, list) {
            state.allRouteList = list
        },
        deleteRoute(state, name) { // 删除某个tags  this.$store.commit('deleteRoute', router name)
            var index = state.businessManagementList.findIndex(el => el.name === name)
            index !== -1 && state.businessManagementList.splice(index, 1)
        },
        //点击tab菜单高亮
        filterMenuActive(state, { path, headerNewData }) {
            // let headerNewData = this.headerNewData
            let value = JSON.parse(JSON.stringify(state.activeMenu))
            headerNewData.map((item, index) => {
                item.children.map((items, idx) => {
                    if(items.children.length != 0) {
                        items.children.map((n) => {
                            if (n.href == path) {
                                state.activeMenu = index
                            }
                        })
                    } else {
                        if (items.href == path) {
                            state.activeMenu = index
                        }
                    }
                })
            })
        },
        //获取header已存在tab路由
        headHopRoute(state, { index, headerNewData}) {
            state.headHopData = index
            let businessManagementList = state.businessManagementList
            let menuData = []
            headerNewData[index].children.filter(item => {
                if (item.children.length > 0) {
                    item.children.filter(items => {
                        businessManagementList.map(i => {
                            if(i.name == items.href) {
                                menuData.push(i)
                            }
                        })
                    })
                } else {
                    // menuData.push(item)
                    businessManagementList.map(i => {
                        if(i.name == item.href) {
                            menuData.push(i)
                        }
                    })
                }
            })
            let activeName
            menuData.length != 0 ? activeName = menuData[0].name : activeName = '/'
            // state.headHopData = menuData.length > 0 ? menuData[0].name : ""
            state.headHopData = menuData[0].name;
        }
    },
};

export default app;