/*
 * @Author: 张小闹
 * @Date: 2020-06-19 14:44:58
 * @LastEditors: 逸帆
 * @LastEditTime: 2020-09-27 16:29:32
 * @FilePath: \super_system\src\router\index.js
 */ 
import Vue from 'vue'
import Router from 'vue-router'
import * as route from './router'
import store from '../store'

Vue.use(Router)

const RouterConfig = {
  // mode: 'history',
  routes: route.routers
}
const router = new Router(RouterConfig)

// 解决 vue-router 3.1 版本以后，使用 router.push 跳转路由报错的问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

var name = 'afterSale_token'
var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
router.beforeEach((to, from, next) => {
  let code = localStorage.getItem('aftersale_code')
  // 此处写登录验证
  if(document.cookie.match(reg)){
    arr = document.cookie.match(reg)[2]
  }else{
    if(to.path === "/login"){
    }else{
      next({path: '/login'})
    }
  }
  
  // 允许直接通过路由:
  if(to.path == '/styleChoice' || to.path == '/styleGoods' || to.path == '/changeImg' || to.path == '/setModel' || to.path == '/listDetail' || to.path == '/deveSet') {
    next();
    return;
  }

  if(arr) {
    if(store.state.app.routerPathList.indexOf(to.path.slice(1)) !== -1) {
      next()
    } else if(from.path === "/login" || to.path === "/") {
      next()
    } else if(store.state.app.routerPathList.length != 3 && document.cookie.match(reg) != null) {
      next('/error')
    } else {
      next()
    }
  } else if(to.path == "/login") {
    next()
  } else {
    next({path: '/login'})
  }

})

router.afterEach((to, from, next) => {
  // if(to.path == '/manyCustomerAudit' && from.path == '/customerDetail') {
  //   to.meta.keepAlive  = true
  // } else {
  //   to.meta.keepAlive  = false
  // }
  
 })

export default router
