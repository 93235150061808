<template>
  <div class="index" ref="headerMenu" v-if="headerNewData.length !== 0">
    <div class="layout">
      <Layout>
        <Header>
          <header-menu
            v-if="headerNewData"
            :header-list="headerNewData"
            :change-module="changeModule"
            @activeMenu="getActiveMenu">
            <div slot="left" class="header-left-title">
              <span @click="goHome">{{codeTitle}}</span>
              <div class="header-button">
                <div
                  :style="{ transform: 'rotateZ(' + (this.shrink ? '-90' : '0') + 'deg' }"
                  type="ghost"
                  class="btn-icon"
                  @click="toggleClick">
                  <Icon type="md-menu" />
                  <!-- <Icon type="navicon-round"></Icon> -->
                  <!-- <Icon type="md-arrow-back" /> -->
                </div>
              </div>
            </div>
            <div slot="right" class="header-right-title">
              <div class="right-button">
                <a href="http://helpcenter.djson.cn/" target="_blank">
                  <img src="../assets/images/manual.png" height="25" width="25" alt />
                </a>
              </div>
              <div class="right-button">
                <Dropdown @on-click="handleLoginName">
                  <span>
                    {{ userMessage.name }}
                    <Icon type="ios-arrow-down"></Icon>
                  </span>
                  <DropdownMenu slot="list">
                    <DropdownItem :name="item.code" v-for="(item, index) of codeList" :key="index" v-if="item.code != code">{{item.name}}</DropdownItem>
                    <DropdownItem name="toMaster">回到首页</DropdownItem>
                    <DropdownItem name="outLogin">退出登录</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </header-menu>
        </Header>
        <Layout class="lay-menu">
          <Sider hide-trigger width="auto">
            <!-- 左侧导航 on-change 点击按钮事件 menu-list相关目录数据-->
            <div
              class="menu"
              :style="{
								width: shrink ? '60px' : '170px',
								overflow: shrink ? 'visible' : 'auto',
								background: bgColor}">
              <dynamic-menu
                v-show="!shrink"
                :header-list="headerNewData"
                :active-menu="activeMenu"
                @on-change="changeMenu"
                :theme="theme"
                v-if="headerNewData.length != 0"
              ></dynamic-menu>
              <!-- 菜单收缩 -->
              <dynamic-menu-shrink
                v-show="shrink"
                :header-list="headerNewData"
                :active-menu="activeMenu"
                @on-change="changeMenu"
                :theme="theme"
                v-if="headerNewData.length != 0"
              ></dynamic-menu-shrink>
            </div>
          </Sider>
          <Layout style="height: 100%">
            <Content v-show="!$store.state.imageModal">
              <div class="content" id="contentPage">
                <!-- <Button @click="$store.state.imageModal = true">显示</Button> -->
                <keep-alive>
                  <router-view v-if="$route.meta.keepAlive"></router-view>
                </keep-alive>
                <router-view v-if="!$route.meta.keepAlive"></router-view>
              </div>
            </Content>
            <!-- <transition name="fade"> -->
            <image-modal v-if="$store.state.imageModal"></image-modal>
            <!-- </transition> -->
          </Layout>
        </Layout>
      </Layout>
    </div>
  </div>
</template>

<script>
import Qs from "qs";
import store from "../store/index.js";
import dynamicMenu from "@/components/dynamic-menu/dynamicMenu.vue";
import dynamicMenuShrink from "@/components/dynamic-menu/dynamicMenuShrink.vue";
import tagsPage from "@/components/tags-page/tags-page.vue";
import headerMenu from "@/components/header-menu/header-menu.vue";
import { mapMutations } from "vuex";

export default {
  name: "Home",
  store,
  components: {
    dynamicMenu,
    dynamicMenuShrink,
    tagsPage,
    headerMenu
  },

  data() {
    return {
      codeList: [], // 模块列表
      codeTitle: '', // 头部title
      theme: "dark",
      userMessage: {name: ''},
      menuTree: [],
      menuIndividual: [],
      menuMain: [],
      elements: [],
      shrink: false,
      activeMenu: 0,
      changeModule: true,
      headerNewData: [],
      routerData: [],
      clientX: 0,
      clientY: 0,
      flag: false,
      idx: 0,
      code: ""
    };
  },

  watch: {
    //监听是否点击顶部导航重启侧边模块
    activeMenu(val, old) {
      this.changeModule = false;
      this.$nextTick(() => {
        this.changeModule = true;
      });
    },
    $route(to, from) {
      const { path, name, query, params, meta } = to;
      this.addTab({
        route: { path, name, query, params, meta },
        type: "push"
      });
      this.setQuery(to);
      localStorage.setItem(
        "businessManagementList",
        JSON.stringify(this.businessList)
      );
      this.filterMenuActive({
        path: to.name,
        headerNewData: this.headerNewData
      });
    }
  },

  computed: {
    bgColor() {
      return this.theme === "dark" ? "#495060" : "#fff";
    },
    color() {
      return this.theme === "dark" ? "rgba(255,255,255,.7)" : "#495060";
    },
    //pags数据
    businessList(data) {
      return this.$store.state.app.businessManagementList;
    },
    activeTabs() {
      return this.$store.state.app.activeTabs;
    },
    headHopData() {
      return this.$store.state.app.headHopData;
    }
  },

  //已成功挂载，相当ready()
  created() {
    let code = localStorage.getItem('aftersale_code')
    this.code = code
    this.codeList = JSON.parse(localStorage.getItem('aftersale_codeList'))
    if(code) {
      if(code == 'refund') {
        this.codeTitle = '售后管理系统'
      } else if (code == 'supplier') {
        this.codeTitle = '商家后台管理'
      } else if(code == 'developer'){
        this.codeTitle = '发展商平台'
      } else if (code == 'settings'){
        this.codeTitle = '移动端设置'
      } else if(code == 'dispatch') {
        this.codeTitle = '调度系统'
      }
    } else {
      this.$router.push({path: '/master'})
      return
    }
    document.title = this.codeTitle + '（档口网）'
    if (sessionStorage.getItem("activeName")) {
      this.activeMenu = Number(sessionStorage.getItem("activeName"));
      this.$store.state.app.activeMenu = this.activeMenu;
    }
    let userMessage = JSON.parse(localStorage.getItem("afterSaleList"));
    if(userMessage) {
      this.userMessage = userMessage
    }
    this.setTabNavList();
    //esc隐藏弹框
    let vm = this;
    document.addEventListener("keyup", function(e) {
      //此处填写你的业务逻辑即可
      if (e.keyCode == 27) {
        vm.$store.state.imageModal = false;
      }
    });

    this.getMenuData();

    document.addEventListener("click", function(e) {
      vm.flag = false;
    });

    if (window.location.href.includes("after")) {
      this.$store.state.dataUrl = "http://z-admin.djson.cn";
    } else if(window.location.href.includes("cosmos.yundang.net")) {
      this.$store.state.dataUrl = "https://cosmos.yundang.net/api";
    }else if (window.location.href.includes("dangkou")) {
      this.$store.state.dataUrl = "http://qin.dangkou.net/api";
    } else if (window.location.href.includes("admin.palidin.me")) {
      this.$store.state.dataUrl = "http://api.admin.palidin.me";
    }else{
      this.$store.state.dataUrl = "http://api.admin.djson.cn"
    }
  },

  //相关操作事件
  methods: {
    ...mapMutations([
      "setTabNavList",
      "addTab",
      "setQuery",
      "filterMenuActive",
      "headHopRoute"
    ]),

    // 跳转回选择页面
    goHome() {
      this.$router.push({path: '/master'})
    },

    //右击事件
    mousedown(event) {
      const text = event.target.innerText.replace(/\s*/g, "");

      event.preventDefault();
      this.clientX = event.clientX;
      this.clientY = event.clientY;
      this.flag = false;

      this.businessList.some((item, idx) => {
        // 仅为右击子tag
        if (item.title == text && text != "款式列表") {
          this.flag = true;
          this.idx = idx;
        }
      });
    },

    // 删除
    clickDeleted(val) {
      let page = this.businessList;
      let idx = this.idx;
      switch (val) {
        case 1:
          page.splice(idx + 1, page.length - 1 - idx);
          page.splice(1, idx - 1);
          this.flag = false;
          this.$nextTick(() => {
            this.$router.push({
              path: page[1].path,
              query: page[1].query
            });
          });
          break;
        case 2:
          this.$router.push({ path: page[0].path });
          page.splice(1, idx - 1);
          this.flag = false;
          break;
        case 3:
          this.$router.push({
            path: page[idx].path,
            query: page[idx].query
          });
          page.splice(idx + 1, page.length - 1 - idx);
          this.flag = false;
          break;
      }
    },

    getMenuData() {
      let code = localStorage.getItem('aftersale_code')
      this.axios.ajax({
        type: "POST",
        url: "/auth/permission",
        data: { code: code },
        success: res => {
          if(res.status == 0) {
            let userMessage = JSON.parse(localStorage.getItem("afterSaleList"));
            if(userMessage) {
              this.userMessage = userMessage
            }
            if(res.data.elements.length == 0) {
              this.$message.error('账户权限不足，请联系管理员添加');
              // this.handleLoginName('outLogin')
              return
            }
            let menus = [
              {
                id: '1',
                name: '',
                icon: '',
                href: '',
                children: res.data.menus
              }
            ];

            this.headerNewData = code == 'refund' ? res.data.menus : menus;
            this.filterMenuActive({
              path: this.$route.name,
              headerNewData: this.headerNewData
            });
          let routerPathList = ['/','master','error']
            res.data.elements.forEach(item => {
              routerPathList.push(item.value);
            });
            this.$store.state.app.routerPathList = routerPathList
            let href = window.location.hash
            let newPath = ''
            if(this.headerNewData[0].children[0].children.length > 0) {
              newPath = this.headerNewData[0].children[0].children[0].href
            } else {
              newPath = this.headerNewData[0].children[0].href
            }
            if(href.length < 3) {
              this.$router.push({
                path: '/' + newPath,
              });
            }
          } else {
            this.$message.error(res.msg);
            handleLoginName('outLogin')
          }
        }
      });
    },

    //获取当前激活导航栏下标
    getActiveMenu(data) {
      sessionStorage.setItem("activeName", data);
      if (data != this.activeMenu) {
        this.headHopRoute({
          index: data,
          headerNewData: this.headerNewData
        });
        let headHopData = this.headHopData;
        //跳转当前header中已生成tabs的路由
        this.$router.push({
          path: headHopData,
          query: this.$route.query
        });
      }
      this.activeMenu = data;
    },

    //关闭tags事件
    selectTags(item) {},
    //点击tags事件
    changeTags(item) {},
    //开关按钮事件
    toggleClick() {
      this.shrink = !this.shrink;
      this.$store.state.toggleModal = !this.$store.state.toggleModal;
    },

    //点击菜单
    changeMenu(name) {},
    hasQueryId() {},
    //切换tabs
    changeTabs(name) {
      let businessList = this.businessList;
      for (let i = 0; i < businessList.length; i++) {
        if (businessList[i].path == name) {
          this.$router.push({
            path: name,
            query: businessList[i].query
          });
          return;
        } else {
          this.$router.push({ path: name });
        }
      }
    },

    // 删除 tabs
    tabRemove(name) {
      let btstop = false;
      let query;
      this.businessList.some((item, idx) => {
        let newPath = this.businessList[idx - 1];
        if (item.path == name) {
          newPath.query
            ? this.$router.push({
                path: newPath.path,
                query: newPath.query
              })
            : this.$router.push({ path: newPath.path });
          this.businessList.splice(idx, 1);
        }
      });
    },

    //顶部导航右侧下拉框
    handleLoginName(name) {
      //退出登录
      if (name == "outLogin") {
        this.delCookie("afterSale_token");
        localStorage.removeItem("afterSaleList");
        this.$router.push({ name: "login" });
      } else if(name == 'toMaster') {
        this.$router.push('master')
      } else {
        localStorage.setItem('aftersale_code',name)
        this.$store.state.app.routerPathList = ['/','master','error']
        setTimeout(() => {
          this.$router.push({path: '/'});
          this.$router.go(0)
        }, 500);
      }
    },

    delCookie(name) {
      var exp = new Date();
      exp.setTime(exp.getTime() - 1);
      var cval = this.getCookie(name);
      if (cval != null)
        document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
    },

    getCookie(name) {
      var arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
      else return null;
    }
  }
};
</script>

<style lang="scss">
.el-image-viewer__btn .el-icon-circle-close{
  color: red
}
.index {
  height: 100%;
  overflow: hidden;
  background: rgb(244, 244, 244);
}
.top-title {
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}
.menu {
  height: 100%;
  transition: width 0.3s;
}
.content {
  overflow: scroll;
  padding: 15px;
  box-sizing: border-box;
  height: 100%;
  .tabBody{
    width: 100%;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  }
  .tags {
    z-index: 999;
    width: 100%;
    background: #fff;
    padding: 3px 5px;
    height: 44px;
  }
}
.router-box {
  height: 100%;
  overflow-x: hidden;
}

.header-left-title {
  float: left;
  padding: 0 60px 0 20px;
  span {
    line-height: 60px;
    font-family: "华文琥珀 Regular", "华文琥珀";
    // font-weight: 800;
    font-style: normal;
    font-size: 24px;
    color: #fff;
    text-align: left;
  }
  .header-button {
    // height:50px;
    display: inline-block;
    cursor: pointer;
    padding: 0px 5px;
    .btn-icon {
      transition: all 0.2s ease;
      border: 0px;
      font-size: 20px;
      color: #fff;
    }
  }
  .ivu-icon {
    display: block;
  }
}
.header-right-title {
  float: right;
  color: #fff;
  cursor: pointer;
  line-height: 60px;
  padding-right: 40px;
  .right-button {
    display: inline-block;
    margin-left: 20px;
    img {
      vertical-align: middle;
    }
    a {
      height: 25px;
      display: inline-block;
      line-height: 25px;
    }
  }
}
.demo-tabs-style > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab {
  border-radius: 0;
  background: #fff;
}
.demo-tabs-style
  > .ivu-tabs.ivu-tabs-card
  > .ivu-tabs-bar
  .ivu-tabs-tab-active {
  border-top: 1px solid #3399ff;
}
.demo-tabs-style
  > .ivu-tabs.ivu-tabs-card
  > .ivu-tabs-bar
  .ivu-tabs-tab-active:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #3399ff;
  position: absolute;
  top: 0;
  left: 0;
}
.demo-ul {
  position: absolute;
  z-index: 9999;
  background-color: white;
  padding: 5px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  a {
    text-decoration: none;
    color: black;
    font-size: 12px;
  }
  .demo-li {
    margin: 0;
    line-height: normal;
    padding: 7px 16px;
    clear: both;
    color: #515a6e;
    font-size: 12px !important;
    white-space: nowrap;
    list-style: none;
    cursor: pointer;
    -webkit-transition: background 0.2s ease-in-out;
    transition: background 0.2s ease-in-out;
  }
  .demo-li:hover {
    background-color: #f5f5f5;
  }
}
//取消头部默认样式
.ivu-layout-header {
  padding: 0 0px!important;
  height: auto!important;
  line-height: auto;
}
.ivu-layout {
  overflow: hidden !important;
}
.ivu-layout-content {
  height: 100%;
}
</style>
