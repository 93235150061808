import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Master from '../views/Master.vue'
import Error from '../views/error.vue'

export const login = {
    path: '/login',
    name: 'login',
    component: Login,
}
export const master = {
    path: '/master',
    name: 'master',
    component: Master,
}

export const  error= {
    path: '/error',
    name: 'error',
    component: Error,
}

export const saleAudit = {
    path: '/',
    name: '售后审核',
    component: Home,
    // redirect: '/onlyCustomerAudit',
    children: [
        {
            path: 'onlyCustomerAudit',
            name: 'onlyCustomerAudit',
            icon: 'ionic',
            meta: {
                active: false,
                title: '客户审核',
                keepAlive: true,
            },
            component: () => import('../views/saleAudit/onlyRefund/customerAudit/customerAudit.vue')
        },
        {
            path: 'onlyFinancialAudit',
            name: 'onlyFinancialAudit',
            icon: 'ionic',
            meta: {
                active: false,
                title: '财务审核',
                keepAlive: true,
            },
            component: () => import('../views/saleAudit/onlyRefund/financialAudit/financialAudit.vue')
        },
        {
            path:'onlyCustomerDetail',
            name:'onlyCustomerDetail',
            meta:{
                keepAlive: false,
                fatherPath: 'onlyCustomerAudit'
            },
            component:()=>import('../views/saleAudit/onlyRefund/customerAudit/onlyCustomerDetail.vue')
        },
        {
            path:'onlyFinancialDetail',
            name:'onlyFinancialDetail',
            meta:{
                keepAlive: false,
                fatherPath: 'onlyFinancialAudit'
            },
            component:()=>import('../views/saleAudit/onlyRefund/financialAudit/onlyFinancialDetail.vue')
        },

        {
            path: 'manyCustomerAudit',
            name: 'manyCustomerAudit',
            icon: 'ionic',
            meta:{
                keepAlive: true
            },
            component: () => import('../views/saleAudit/manyRefund/customerAudit/customerAudit.vue'),
        },
        {
            path:'customerDetail',
            name:'customerDetail',
            meta:{
                keepAlive: false,
                fatherPath: 'manyCustomerAudit'
            },
            component: ()=>import('../views/saleAudit/manyRefund/customerAudit/customerDetail.vue')

        },

        {
            path: 'manyFinancialAudit',
            name: 'manyFinancialAudit',
            icon: 'ionic',
            meta:{
                keepAlive: true
            },
            component: () => import('../views/saleAudit/manyRefund/financialAudit/financialAudit.vue')
        },
        {
            path: 'financialDetail',
            name: 'financialDetail',
            icon: 'ionic',
            meta:{
                keepAlive: false,
                fatherPath: 'manyFinancialAudit'
            },
            component: () => import('../views/saleAudit/manyRefund/financialAudit/financialDetail.vue')
        },
        {
            path:'bagReturn',
            name:'bagReturn',
            icon: 'ionic',
            meta:{
                keepAlive: true
            },
            component:()=>import('../views/saleAudit/manyRefund/bagReturn/bagReturn.vue')
        },
        {
            path:'bagReturnDetail',
            name:'bagReturnDetail',
            icon: 'ionic',
            meta:{
                keepAlive: false,
                fatherPath: 'bagReturn'
            },
            component:()=>import('../views/saleAudit/manyRefund/bagReturn/bagReturnDetail.vue')
        },
			{
				path:'offlineBillAdd',
				name:'offlineBillAdd',
				icon: 'ionic',
				meta:{
					keepAlive: true
				},
				component:()=>import('../views/saleAudit/offlineBill/offlineBillAdd.vue')
			},
			{
				path:'offlineBill',
				name:'offlineBill',
				icon: 'ionic',
				meta:{
					keepAlive: true
				},
				component:()=>import('../views/saleAudit/offlineBill/offlineBill.vue')
			},
			{
				path:'offlineBillDetail',
				name:'offlineBillDetail',
				icon: 'ionic',
				meta:{
					keepAlive: false,
					fatherPath: 'offlineBill'
				},
				component:()=>import('../views/saleAudit/offlineBill/offlineBillDetail.vue')
			},
    ]
}
export const saleList = {
    path: '/',
    name: '售后列表',
    component: Home,
    children: [
        {
            path: 'saleList',
            name: 'saleList',
            icon: 'ionic',
            meta:{
                keepAlive: true
            },
            component: () => import('../views/saleList/saleList.vue')
        },
        {
            path:'aftersaleDetail',
            name:'aftersaleDetail',
            meta:{
                keepAlive: false,
                fatherPath: 'saleList'
            },
            component:()=>import('../views/saleList/aftersaleDetail.vue')

        }
    ]
}
export const anomaly = {
    path: '/',
    name: '收货单',
    component: Home,
    children: [
        {
            path: 'controversial',
            name: 'controversial',
            icon: 'ionic',
            meta:{
                keepAlive: true
            },
            component: () => import('../views/receivingNote/controversial/controversial.vue')
        },
        {
            path:'controversialDetail',
            name:'controversialDetail',
            meta:{
                keepAlive: false,
                fatherPath: 'controversial'
            },
            component:()=>import('../views/receivingNote/controversial/controversialDetail.vue')
        },
        {
            path: 'anomaly',
            name: 'anomaly',
            icon: 'ionic',
            meta: {
                keepAlive: true,
            },
            component: () => import('../views/receivingNote/anomaly/anomaly/anomaly.vue')
        },
        {
            path:'anormalDetail',
            name:'anormalDetail',
            meta: {
                keepAlive: false,
                fatherPath: 'anomaly'
            },
            component:()=>import('../views/receivingNote/anomaly/anomaly/anormalDetail.vue')
        },
        {
            path: 'incomplete',
            name: 'incomplete',
            icon: 'ionic',
            meta:{
                keepAlive: false
            },
            component: () => import('../views/receivingNote/anomaly/incomplete/incomplete.vue')
        },
        {
            path: 'scrapReturn',
            name: 'scrapReturn',
            icon: 'ionic',
            meta:{
                keepAlive: false
            },
            component: () => import('../views/receivingNote/anomaly/incomplete/scrapReturn.vue')
        },
        {
            path: 'scrapReturnDetail',
            name: 'scrapReturnDetail',
            icon: 'ionic',
            meta:{
                keepAlive: false,
                fatherPath: 'scrapReturn'
            },
            component: () => import('../views/receivingNote/anomaly/incomplete/scrapReturnDetail.vue')
        },
        {
            path: 'register',
            name: 'register',
            icon: 'ionic',
            meta:{
                keepAlive: false
            },
            component: () => import('../views/receivingNote/register/register.vue')
        },
        {
            path: 'stockPending',
            name: 'stockPending',
            icon: 'ionic',
            meta:{
                keepAlive: true
            },
            component: () => import('../views/receivingNote/stockPending/stockPending.vue')
        },
        {
            path:'stockPendingDetail',
            name:'stockPendingDetail',
            meta:{
                keepAlive: false,
                fatherPath: 'stockPending'
            },
            component:()=>import('../views/receivingNote/stockPending/stockPendingDetail.vue')
        },
        {
            path: 'noteList',
            name: 'noteList',
            icon: 'ionic',
            meta:{
                keepAlive: true
            },
            component: () => import('../views/receivingNote/noteList/noteList.vue')
        },
        {
            path:'noteListDetail',
            name:'noteListDetail',
            meta:{
                keepAlive: false,
                fatherPath: 'noteList'
            },
            component:()=>import('../views/receivingNote/noteList/noteListDetail.vue')

        },
        {
            path: 'qualityControl',
            name: 'qualityControl',
            icon: 'ionic',
            meta:{
                keepAlive: true
            },
            component: () => import('../views/receivingNote/qualityControl/qualityControl.vue')
        },
        {
            path:'qualityDetail',
            name:'qualityDetail',
            meta:{
                keepAlive: false,
                fatherPath: 'qualityControl'
            },
            component:()=>import('../views/receivingNote/qualityControl/qualityDetail.vue')
        }
    ]
}

export const strategy = {
    path: '/',
    name: '业务策略',
    component: Home,
    children: [
        {
            path: 'warehouseAbout',
            name: 'warehouseAbout',
            icon: 'ionic',
            meta:{
                keepAlive: false
            },
            component: () => import('../views/strategy/warehouse/warehouseAbout.vue')
        },
        {
            path:'collectionRecord',
            name:'collectionRecord',
            meta:{
                keepAlive: false
            },
            component:()=>import('../views/strategy/collection/collectionRecord.vue')

        },
        {
            path:'addCollection',
            name:'addCollection',
            meta:{
                keepAlive: false
            },
            component:()=>import('../views/strategy/collection/addCollection.vue')

        }
    ]
}

export const supplier = {
    path: '/',
    name: '供应商中心',
    component: Home,
        redirect: '/supplier',
    children: [
         {
    path: 'supplier',
    name: 'supplier',
    icon: 'ionic',
    meta: {
        active: false,
        title: '供应商中心 / 供应商列表',
        keepAlive: true,
    },
    component: () => import('../views/supplierCenter/supplier/index.vue'),
},
{
		path: 'supplierAccount',
		name: 'supplierAccount',
		icon: 'ionic',
		meta: {
			active: false,
			title: '商家财务列表',
			keepAlive: true
		},
		component: () => import('../views/supplierCenter/supplier/account.vue'),
},
{
    path: 'supplierDetail',
    name: 'supplierDetail',
    icon: 'ionic',
    meta: {
        active: false,
        title: '供应商中心 / 保证金详情',
        fath: 'supplier',
        keepAlive: false,
    },
    component: () => import('../views/supplierCenter/supplier/details.vue'),
},
{
    path: 'addSupplier',
    name: 'addSupplier',
    icon: 'ionic',
    meta: {
        active: false,
        title: '供应商中心 / 充值保证金',
        fath: 'supplier',
        keepAlive: false,
    },
    component: () => import('../views/supplierCenter/supplier/addSupplier.vue'),
},
{
    path: 'supplierComm',
    name: 'supplierComm',
    icon: 'ionic',
    meta: {
        active: false,
        title: '供应商商品列表',
        fath: 'supplier',
        keepAlive: false,
    },
    component: () => import('../views/supplierCenter/supplier/supplierComm.vue'),
},

// 动态评分模块
{
    path: 'dynamicScore',
    name: 'dynamicScore',
    icon: 'ionic',
    meta: {
        active: false,
        title: '供应商中心 / 评分分析',
        keepAlive: false,
    },
    component: () => import('../views/supplierCenter/dynamicScore/index.vue'),
},

{
    path: 'historyScore',
    name: 'historyScore',
    icon: 'ionic',
    meta: {
        active: false,
        title: '评分分析 / 历史得分',
        fath: 'dynamicScore',
        keepAlive: false,
    },
    component: () => import('../views/supplierCenter/dynamicScore/historyScore.vue'),
},
{
    path: 'classNumber',
    name: 'classNumber',
    icon: 'ionic',
    meta: {
        active: false,
        title: '评分分析 / 档次人数',
        fath: 'dynamicScore',
        keepAlive: false,
    },
    component: () => import('../views/supplierCenter/dynamicScore/classNumber.vue'),
},

// 数据分析
{
    path: 'analysis',
    name: 'analysis',
    icon: 'ionic',
    meta: {
        active: false,
        title: '数据分析',
        keepAlive: false,
    },
    component: () => import('../views/supplierCenter/analysis/index.vue'),
},
{
    path: 'analysisDetail',
    name: 'analysisDetail',
    icon: 'ionic',
    meta: {
        active: false,
        title: '供应商分析',
        keepAlive: false,
    },
    component: () => import('../views/supplierCenter/analysis/details.vue')
},

// 评分设置:
{
    path: 'settingScore',
    name: 'settingScore',
    icon: 'ionic',
    meta: {
        active : false,
        title: '评分设置',
        keepAlive:false,
    },
    component: () => import('../views/supplierCenter/settingScore/index.vue')
},

// 供应商评分
{
    path : 'suppliScore',
    name : 'suppliScore',
    icon : 'ionic',
    meta : {
        active : false,
        title : '供应商评分',
        keepAlive : false
    },
    component: () => import('../views/supplierCenter/suppliScore/index.vue')
},
{
    path: 'businessAuth',
    name: 'businessAuth',
    icon: 'ionic',
    meta: {
        active: false,
        title: '业务设置',
        keepAlive: false,
    },
    component: () => import('../views/financeCenter/business/index.vue'),
},
{
    path: 'frozenAmount',
    name: 'frozenAmount',
    icon: 'ionic',
    meta: {
        active: false,
        title: '冻结金额',
        keepAlive: false
    },
    component: () => import('../views/financeCenter/frozen/frozenAmount.vue')
},

{
    path: 'paymentSlip',
    name: 'paymentSlip',
    icon: 'ionic',
    meta: {
        active: false,
        title: '付款单',
        keepAlive: false
    },
    component: () => import('../views/financeCenter/paymentSlip/paymentSlip.vue')
},
{
    path: 'paymentDetail',
    name: 'paymentDetail',
    icon: 'ionic',
    meta: {
        active: false,
        fath: 'paymentSlip',
        title: '付款单详情',
        keepAlive: false
    },
    component: () => import('../views/financeCenter/paymentSlip/paymentDetail.vue')
},
{
    path: 'settlement',
    name: 'settlement',
    icon: 'ionic',
    meta: {
        active: false,
        title: '结款单',
        keepAlive: false
    },
    component: () => import('../views/financeCenter/settlement/settlement.vue')
},
{
    path: 'settleDetail',
    name: 'settleDetail',
    icon: 'ionic',
    meta: {
        active: false,
        fath: 'settlement',
        title: '结款单详情',
        keepAlive: false
    },
    component: () => import('../views/financeCenter/settlement/settleDetail')
},
{
    path: 'reconciliation',
    name: 'reconciliation',
    icon: 'ionic',
    meta: {
        active: false,
        title: '对账单',
        keepAlive: true,
    },
    component: () => import('../views/financeCenter/reconciliation/index.vue'),
 },
 {
    path: 'recondilDetail',
    name: 'recondilDetail',
    icon: 'ionic',
    meta: {
        active: false,
        title: '对账单详情',
        fath: 'reconciliation',
        keepAlive: false,
    },
    component: () => import('../views/financeCenter/reconciliation/recondilDetail.vue'),
 },
 {
    path: 'goodsDetail',
    name: 'goodsDetail',
    icon: 'ionic',
    meta: {
        active: false,
        title: '商品详情',
        fath: 'recondilDetail',
        keepAlive: false
    },
    component: () => import('../views/financeCenter/reconciliation/goodsDetail.vue')
 },
 {
    path: 'manualView',
    name: 'manualView',
    icon: 'ionic',
    meta: {
        active: false,
        title: '手动调账单',
        fath: 'recondilDetail',
        keepAlive: false
    },
    component: () => import('../views/financeCenter/reconciliation/manualView')
 },
 {
    path: 'receipt',
    name: 'receipt',
    icon: 'ionic',
    meta: {
        active: false,
        title: '收货单',
        keepAlive: true,
    },
    component: () => import('../views/billCenter/receipt/index.vue'),
},
{
    path: 'receiptDetail',
    name: 'receiptDetail',
    icon: 'ionic',
    meta: {
        active: false,
        title: '单据中心 / 收货单详情',
        fath: 'supplier',
        keepAlive: false,
    },
    component: () => import('../views/billCenter/receipt/receiptDetail.vue'),
},
{
    path: 'returnGoods',
    name: 'returnGoods',
    icon: 'ionic',
    meta: {
        active: false,
        title: '单据中心 / 退货单',
        fath: 'supplier',
        keepAlive: true,
    },
    component: () => import('../views/billCenter/returnGoods/index.vue'),
},
{
    path: 'returnDetail',
    name: 'returnDetail',
    icon: 'ionic',
    meta: {
        active: false,
        title: '单据中心 / 退货单详情',
        fath: 'supplier',
        keepAlive: false,
    },
    component: () => import('../views/billCenter/returnGoods/returnDetail.vue'),
},


    ]
}

// 档口网移动端风格设置模块
export const style = {
    path: '/',
    name: '风格设置',
    component: Home,
    redirect: '/mobileHome',
    children: [
        {
            path: 'mobileHome',
            name: 'mobileHome',
            icon: 'ionic',
            meta: {
                active: false,
                title: '设置首页',
                keepAlive: false,
            },
            component: () => import('../views/dkMobile/home/mobileHome.vue')
        },
        {
            path: 'changeImg',
            name: 'changeImg',
            icon: 'ionic',
            meta: {
                active: false,
                title: '替换图片',
                keepAlive: false,
            },
            component: () => import('../views/dkMobile/img/changeImg.vue')
        },
        {
            path: 'setAdvertisement',
            name: 'setAdvertisement',
            icon: 'ionic',
            meta: {
                active: false,
                title: '设置广告',
                keepAlive: false,
            },
            component: () => import('../views/dkMobile/img/setAdvertisement.vue')
        },
        {
            path: 'setArea',
            name: 'setArea',
            icon: 'ionic',
            meta: {
                active: false,
                title: '设置专区',
                keepAlive: false,
            },
            component: () => import('../views/dkMobile/specialArea/setArea.vue')
        },
        {
            path: 'selectArea',
            name: 'selectArea',
            icon: 'ionic',
            meta: {
                active: false,
                title: '选择专区商品',
                keepAlive: false,
            },
            component: () => import('../views/dkMobile/specialArea/selectArea.vue')
        },
        {
            path: 'addClass',
            name: 'addClass',
            icon: 'ionic',
            meta: {
                active: false,
                title: '新增分类',
                keepAlive: false,
            },
            component: () => import('../views/dkMobile/addClass/index.vue')
        },
        {
            path: 'manufacturer',
            name: 'manufacturer',
            icon: 'ionic',
            meta: {
                active: false,
                title: '入驻厂商',
                keepAlive: false,
            },
            component: () => import('../views/dkMobile/manufacturer/index.vue')
        },

        {
            path: 'stylePavilion',
            name: 'stylePavilion',
            icon: 'ionic',
            meta: {
                active: false,
                title: '风格设置 / 风格馆',
                keepAlive: false,
            },
            component: () => import('../views/dkMobile/style/index.vue')
        },
        {
            path: 'styleDetail',
            name: 'styleDetail',
            icon: 'ionic',
            meta: {
                active: false,
                fath: 'stylePavilion',
                title: '风格设置 / 风格馆 / 风格详情',
                keepAlive: false,
            },
            component: () => import('../views/dkMobile/style/detail.vue')
        },
        {
            path: 'styleChoice',
            name: 'styleChoice',
            icon: 'ionic',
            meta: {
                active: false,
                fath: 'styleDetail',
                title: '风格设置 / 风格馆 / 模板选择',
                keepAlive: false,
            },
            component: () => import('../views/dkMobile/style/choice-model.vue')
        },
        {
            path: 'styleGoods',
            name: 'styleGoods',
            icon: 'ionic',
            meta: {
                active: false,
                fath: 'styleChoice',
                title: '风格设置 / 风格馆 / 商品列表',
                keepAlive: false,
            },
            component: () => import('../views/dkMobile/style/goods-list.vue')
        },





        // 年龄
        {
            path: 'ageNode',
            name: 'ageNode',
            icon: 'ionic',
            meta: {
                active: false,
                title: '年龄节点',
                keepAlive: true,
            },
            component: () => import('../views/dkMobile/age/index.vue')
        },
        {
            path: 'setClass',
            name: 'setClass',
            icon: 'ionic',
            meta: {
                active: false,
                title: '类别设置',
                keepAlive: false,
            },
            component: () => import('../views/dkMobile/class/index.vue')
        },
        {
            path: 'hotVocabulary',
            name: 'hotVocabulary',
            icon: 'ionic',
            meta: {
                active: false,
                title: '热门词汇',
                keepAlive: true,
            },
            component: () => import('../views/dkMobile/hot/index.vue')
        },
        {
            path: 'setMember',
            name: 'setMember',
            icon: 'ionic',
            meta: {
                active: false,
                title: '业务设置',
                keepAlive: true,
            },
            component: () => import('../views/dkMobile/member/setMember.vue')
        },
        {
            path: 'setAdver',
            name: 'setAdver',
            icon: 'ionic',
            meta: {
                active: false,
                title: '广告图设置',
                keepAlive: false,
            },
            component: () => import('../views/dkMobile/adver/setAdver.vue')
        },
        {
            path: 'setBgImg',
            name: 'setBgImg',
            icon: 'ionic',
            meta: {
                active: false,
                title: '我的背景图',
                keepAlive: false
            },
            component: () => import('../views/dkMobile/bgImg/setBgImg.vue')
        }
    ]
}

// 发展商模块
export const developer = {
    path: '/',
    name: '发展商后台',
    component: Home,
    redirect: '/developer',
    children: [
        {
            path: 'developer',
            name: 'developer',
            icon: 'ionic',
            meta: {
                active: false,
                title: '发展商列表',
                keepAlive: true,
            },
            component: () => import('../views/developer/developer/index.vue')
        },
        {
            path: 'deveDetails',
            name: 'deveDetails',
            icon: 'ionic',
            meta: {
                active: false,
                title: '发展商详情',
            },
            component: () => import('../views/developer/developer/details.vue')
        },
        {
            path: 'deveSet',
            name: 'deveSet',
            icon: 'ionic',
            meta: {
                active: false,
                title: '发展商设置'
            },
            component: () => import('../views/developer/developer/deveSet.vue')
        }

    ]
}

// 调度模块:
export const dispatch = {
    path: '/',
    name: '调度管理',
    component: Home,
    redirect: '/dispatch',
    children: [
        {
            path: 'dispatch',
            name: 'dispatch',
            icon: 'ionic',
            meta: {
                active: false,
                title: '调度面板',
                keepAlive: true
            },
            component: () => import('../views/dispatch/index/index.vue')
        },
        {
            path: 'dispatchList',
            name: 'dispatchList',
            icon: 'ionic',
            meta: {
                active: false,
                title: '调度单',
                keepAlive: true
            },
            component: () => import("../views/dispatch/dispatch-list/dispatchList.vue")
        },
        {
            path: 'listDetail',
            name: 'listDetail',
            icon: 'ionic',
            meta: {
                active: false,
                title: '调度单详情',
                fath: 'dispatchList',
                keepAlive: false
            },
            component: () => import('../views/dispatch/dispatch-list/listDetail.vue')
        },
        {
            path: 'dispatchPlan',
            name: 'dispatchPlan',
            icon: 'ionic',
            meta: {
                active: false,
                title: '调度单计划',
                fath: 'dispatchPlan',
                keepAlive: false
            },
            component: () => import('../views/dispatch/dispatch-plan/index.vue')
        }
    ]
}

export const routers = [
    login,
    error,
    master,
    saleAudit,
    saleList,
    anomaly,
    strategy,
    supplier,
    style,
    developer,
    dispatch
]
