<template>
	<div ref="scrollCon" class="tag-omponent">
		<div class="tags-list" :style="{ left : tagBodyLeft + 'px'}" @contextmenu.prevent="mousedown($event)">
			<Tag
			class="tags-chilren"
			type="dot"
			v-for="(item,index) in pageTagsList"
			:closable="(item.path == '/') ? false : true"
			color="primary"
			:name="item.path"
			:color="(currentPageName == item.path)? 'primary' : 'default'"
			:key="index"
			@click.native="linkTo(item)"
			@on-close="tagsClose(item,index)"

			ref='tagsPageOpened'>
			{{ item.title }}
			</Tag>
		</div>
		<transition name="fade">
			<div class="tabs-dropdown"
				v-show="dropdown"
				:style="{ left:dropdownLeft + 'px' , top:dropdownTop + 'px'}">
				<div  class="drop-list"
				v-for="(item,index) in dropList"
				@click="dropClick(item,index)"
				:class="{'drop-active' : item.title == activeName}">
						{{item.title}}
				</div>
			</div>
		</transition>
			<!-- <Button @click="tagPush" style="position:absolute;top:100px;z-index:1000">添加</Button> -->
	</div>
</template>

<script>
	export default {
		props: {
			pageTagsList: Array,
			headerList: Array,
			activeMenu: Number
		},
		data() {
			return {
				color:'',
				currentPageName:'/',
				iconShow:false,
				refsTag:'',
				dropdownLeft:100,
				dropdownTop:100,
				tagBodyLeft:10,
				dropdown:false,
				activeName:'',
				dropList:[
					{
						title:'关闭所有'
					},{
						title:'关闭其他'
					}
				],
				currentRouterName:''
			}
		},
		computed:{
			
		},
		watch: {
			//监听路由
			'$route'(to,from){
				this.currentPageName = to.name
				//dom更新之后执行回调(缺少会有位移错误的问题)
				this.$nextTick(() => {
					this.refsTag.forEach((item, index) => {
	                    if (to.name === item.name) {
	                        let tag = this.refsTag[index].$el;
	                        this.moveToView(tag);
	                    }
	                });
				})
			}
		},
		methods: {
			// 右击事件
			mousedown(e) {
				var e = e || window.event
				this.currentRouterName = e.target.innerText.replace(/(^\s*)|(\s*$)/g, "")
				this.dropdown = true 
				this.dropdownLeft = e.clientX - 190
				this.dropdownTop = e.target.offsetTop + 20
			},
			//点击关闭按钮
			dropClick(item,index) {
				this.pageTagsList.forEach((item,i) => {
					if(item.title == this.currentRouterName){
						if(index == 1){
							// 关闭其他
							this.pageTagsList.splice(i+1, this.pageTagsList.length)
							this.pageTagsList.splice(1, i-1)
							this.$router.push({path: item.path})
						} else {
							//关闭所有
							this.pageTagsList.splice(1, this.pageTagsList.length)
							this.$router.push({path: '/'})
						}
					}
				})
				this.tagBodyLeft = 5
				this.dropdown = false
			},
			//tags位移函数
			moveToView (tag) {
			    if (tag.offsetLeft < -this.tagBodyLeft) {
			        // 标签在可视区域左侧
			        this.tagBodyLeft = -tag.offsetLeft;
			    } else if (tag.offsetLeft > - this.tagBodyLeft && tag.offsetLeft + tag.offsetWidth < -this.tagBodyLeft + this.$refs.scrollCon.offsetWidth - 10) {
			        // 标签在可视区域
			        this.tagBodyLeft = Math.min(0, this.$refs.scrollCon.offsetWidth - 10 - tag.offsetWidth - tag.offsetLeft - 20) + 10;
			    } else {
			        // 标签在可视区域右侧
			        this.tagBodyLeft = -(tag.offsetLeft - (this.$refs.scrollCon.offsetWidth - tag.offsetWidth));
			    }
			},
			handleTagsOption(type) {
			},
			//点击关闭按钮
			tagsClose(item,index) {
				let pageOpenedList = this.$store.state.app.pageOpenedList
					if(JSON.stringify(pageOpenedList).indexOf(JSON.stringify(item)) != -1){
					pageOpenedList.splice(index,1)
				}
				this.linkTo(pageOpenedList[index-1])
			},
			//点击tag跳转路由
			linkTo(item) {
				this.$router.push({path:item.path})
				let activeMenu = this.activeMenu
				//点击tab切换侧边导航高亮
				this.headerList[activeMenu].activeRouter = item.path
			},
			tagPush() {
				// this.pageTagsList.push(this.$store.state.app.pageOpenedList[0].title)
			}
		},
		mounted() {
			this.refsTag = this.$refs.tagsPageOpened;
			document.addEventListener('click', (e) => {
				this.dropdown = false			
			})
		}
	}
</script>

<style lang="scss">
	.tag-omponent{
		width:100%;
		height:100%
	}
	.tags-list{
		position:absolute;
		white-space:nowrap;
		transition: left 0.3s;
	}
	.chevron-left{
		position: absolute;
	    left: 0px;
	    background: #fff;
	    height: 42px;
	    width: 15px;
	    top: -0px;
	    text-align: center;
	    border-right: 1px solid #ddd;
	    border-radius: 5px;
	    cursor:pointer;
	    z-index:100;
	}
    .icon{
    	line-height:42px;
    }
	.chevron-right{
		position: absolute;
	    right: 0px;
	    background: #fff;
	    height: 42px;
	    width: 15px;
	    top: -0px;
	    text-align: center;
	    border-left: 1px solid #ddd;
	    border-radius: 5px;
	    cursor:pointer;
	    z-index:100;
	}
	.close-all-tag-con{
        position: absolute;
        right: 0;
        top: 0;
        box-sizing: border-box;
        padding-top: 8px;
        text-align: center;
        width: 110px;
        height: 100%;
        background: white;
        box-shadow: -3px 0 15px 3px rgba(0, 0, 0, .1);
        z-index: 10;
    }
    .tabs-dropdown{
			min-width:138px;
			overflow: auto;
			padding:5px 0;
			background-color: #fff;
			box-sizing: border-box;
			border-radius: 4px;
			box-shadow: 0 1px 6px rgba(0,0,0,.2);
			position: absolute;
			z-index: 900;
			.drop-list{
				margin: 0;
			    line-height: normal;
			    padding: 10px 26px;
			    clear: both;
			    color: #495060;
			    font-size: 12px!important;
			    white-space: nowrap;
			    list-style: none;
			    cursor: pointer;
			}
			.drop-list:hover{
				background:#e4e7ed;
				transition: background .2s ease-in-out;
			}
			.drop-active{
				background:#409eff!important;
				color:#fff
			}
		}
</style>

<style scoped>
	/*//滑过动画效果*/
	.fade-enter-active, .fade-leave-active {
      transition: all .3s ease;
    }
    .fade-enter, .fade-leave-active {
      	transform: translateY(-10px);
  		opacity: 0;
    }
</style>