<!--
 * @Author: 张小闹
 * @Date: 2020-07-29 15:26:27
 * @LastEditors: 张小闹
 * @LastEditTime: 2020-08-01 13:39:03
 * @FilePath: \newVue-aftersale\src\views\error.vue
--> 
<template>
  <div class="headerClass">
    <div>
      <div class="textColor">权限不足,建议联系管理员添加，或者重新登录</div>
      <div class="buttonClass">
        <el-button  @click="goBack" style="width: 100px">后退</el-button>
        <el-button type="primary" @click="goHome" style="width: 100px">返回首页</el-button>
        <el-button type="success" @click="goLong" style="width: 100px">重新登录</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return{

    }
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    goHome() {
      this.$router.push({path: '/'});
    },

    goLong() {
      this.delCookie("afterSale_token");
      localStorage.removeItem("afterSaleList");
      this.$router.push({ name: "login" });
    },

    delCookie(name) {
      var exp = new Date();
      exp.setTime(exp.getTime() - 1);
      var cval = this.getCookie(name);
      if (cval != null)
        document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
    },

    getCookie(name) {
      var arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
      else return null;
    }
  }
}
</script>
<style lang="scss" scoped>
  .headerClass{
    width: 100vw;
    height: 100vh;
    background-image: url('../assets/images/404.jpg');
    background-repeat: no-repeat;
    background-size:100% 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 100px 100px 0;
  }
  .textColor{
    font-size: 20px;
    color: #409eff;
    padding: 30px 0;
  }
  .buttonClass{
    display: flex;
    justify-content: space-around;
  }
</style>