/*
 * @Author: palidin
 * @Date: 2021-6-15 15:41:31
 * @LastEditors: 逸帆
 * @LastEditTime: 2021-6-15 15:41:31
 */
let Formatter = {
	preSubmit: function (data) {
		let r = {};
		for (let i in data) {
			let v = data[i];
			if (!v || v instanceof Function) {
				continue;
			}
			if (v instanceof Object) {
				r[i] = JSON.stringify(v);
			} else {
				r[i] = v;
			}
		}
		return r;
	}
};

export default Formatter
