<template>
  <div class="dynamic-menu" v-if="headerList.length != 0">
    <slot name="top"></slot>
    <!-- :active-name="headerList[activeMenu].activeRouter" -->
    <!-- headerList[activeMenu].activeIndex -->
    <Menu
      :active-name="activeHref"
      :open-names="activeIndex"
      :theme="theme"
      ref="updata"
      style="height:100%;width:auto"
      @on-select="changeMenu"
      @on-open-change="openChild"
    >
      <template v-for="(item,index) in headerList[activeMenus].children">
        <Submenu v-if="item.children.length != 0" :name="index" :key="index">
          <template slot="title">
            <Icon :type="item.icon"></Icon>
            <span>{{item.name}}</span>
          </template>
          <template v-for="items in item.children">
            <MenuItem v-if="items.children" :name="items.href">{{items.name}}</MenuItem>
            <Submenu v-if="items.children.length != 0" name="3">
              <template slot="title">{{items.name}}</template>
              <template v-for="i in items.children">
                <MenuItem :name="i.href">{{i.name}}</MenuItem>
              </template>
            </Submenu>
          </template>
        </Submenu>
        <MenuItem v-else :key="item.code" :name="item.href">
          <Icon :type="item.icon"></Icon>
          <span>{{item.name}}</span>
        </MenuItem>
      </template>
    </Menu>
  </div>
</template>

<script>
// import axios from 'Assets/js/axios.js'
// import { mainHome } from '@/views/home/router/router'
import Qs from "qs";
export default {
  name: "Home",
  props: {
    headerList: Array,
    activeMenu: Number,
    theme: String
  },
  data() {
    return {
      activeMenus: this.activeMenu,
      menuHeight: "",
      activeIndex: [0],
      activeHref: "/"
    };
  },
  watch: {
    //监听路由
    $route(to, from) {
      // //生成tabs(都在这里)
      // let allRouteList
      //  // = this.$store.state.app.allRouteList
      // let pageOpenedList
      // allRouteList = this.$router.options.routes[1].children
      // allRouteList.forEach(item => {
      //   if(item.path == to.name) {
      //     pageOpenedList = item
      //   }
      // })
      // // this.$router.push({ name: to.name });
      // let appPage = this.$store.state.app.pageOpenedList;
      // let btstop = false;
      // //判断是否已存在tabs(存在添加否则不操作)
      // let data = appPage.filter(value => {
      // //过滤数组元素
      //   return value.path.includes(pageOpenedList.path);
      // });
      // if (data.length == 0) {
      //   // this.$store.commit("increateTag", pageOpenedList);
      // }

			//切换删除tab时侧边菜单高亮切换
      let activeMenu = this.$store.state.app.activeMenu;
      let currentIndex;
      this.headerList[activeMenu].children.forEach((item, index) => {
        if(item.children.length != 0) {
          item.children.forEach((items, idx) => {
            if (to.name == items.href) {
              this.activeHref = items.href;
              this.activeIndex.push(index)
              currentIndex = index;
              if (this.activeIndex.indexOf(index)) { }
            }
          });
        } else {
          if(to.name == item.href) {
            this.activeHref = item.href
          }
        }
      });
      this.$nextTick(function() {
        this.$refs.updata.updateOpened();
        this.$refs.updata.updateActiveName();
      });
    },
    activeMenu(val){
      this.activeMenus = val
    },
  },
  //已成功挂载，相当ready()
  created() {
    this.menuHeight = document.body.clientHeight;
    const hash = this.$route.name
    if(hash) {
      let activeMenu = this.$store.state.app.activeMenu;
      this.activeMenus = this.$store.state.app.activeMenu
			this.headerList[activeMenu].children.forEach((item, index) => {
        if(item.children.length != 0) {
          item.children.forEach((items, idx) => {
            if (hash == items.href || this.$route.meta.fatherPath == items.href) {
              this.activeHref = items.href;
              this.activeIndex = [index]
            } 
          });
        } else {
          if (hash == item.href || this.$route.meta.fatherPath == item.href) {
            this.activeHref = item.href;
            this.activeIndex = [index]
          }
        }
			});
      //activeHref activeIndex 变化后更新组件
      // this.$nextTick(function() {
      //   this.$refs.updata.updateOpened();
      //   this.$refs.updata.updateActiveName();
      // });
		}
  },
  methods: {
    openChild(name) {
      //重新赋值解决展开后收起点击tab无法再次打开的问题
      this.activeIndex = name
    },
    // 点击菜单事件
    changeMenu(active) {
      this.$store.state.imageModal = false
      this.$router.push({ name: active });
			//创建tags
			this.$emit("on-change", active);
		}
	}
};
</script>
<style lang="scss">
.dynamic-menu {
  height: 100%;
  // border-top: 1px solid #dddee1;
}
.drop-icon {
  color: rgba(255, 255, 255, 0.7);
  margin-left: 18px;
}
.ivu-menu-dark.ivu-menu-vertical .ivu-menu-item {
  width: 170px;
  overflow: hidden;
}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item {
  width: 170px;
  overflow: hidden;
}
.ivu-menu-vertical .ivu-menu-item,
.ivu-menu-vertical .ivu-menu-submenu-title {
  width: 170px;
  overflow: hidden;
}
</style>