<!--
 * @Author: 逸帆
 * @Date: 2020-08-26 10:35:29
 * @LastEditors: 逸帆
 * @LastEditTime: 2020-10-08 14:27:23
 * @FilePath: \super_system\src\App.vue
-->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return{}
  },
  created() {
    document.title = this.$global.title.title
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
	height: 100%;
	overflow: hidden;
  color: #2c3e50;
  font-size: 12px;
}
.el-image-viewer__btn .el-icon-circle-close{
  color: red
}

</style>
