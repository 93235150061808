<template>
	<div class="header-height" @click.stop="$store.state.imageModal = false">
		<slot name="left"></slot>
		<div class="header-list">
			<div class="header-list-title" v-for="(item,index) in headerList" :key="index" @click="changeTitle(item,index,$event)" :class="{'title-active' : item.name == activeName}">
				<span>{{ item.name }}</span>
			</div>
		</div>
		<slot name="right"></slot>
	</div>
</template>

<script>
	export default {
		props:{
			headerList:Array
		},
		watch:{
			'$route'(to,from) {
				this.activeMenu
			}
		},
		data() {
			return {
				activeName:'售后审核',
				// activeMenu: 0
			}
		},
		computed: {
			activeMenu() {
				if(this.headerList.length != 0) {
					let activeMenu = this.$store.state.app.activeMenu
					this.activeName = this.headerList[activeMenu].name
					this.$emit('activeMenu',activeMenu)
					return activeMenu
				}
			},
		},

		methods: {
			changeTitle(item,index,e) {
				this.$store.state.app.activeMenu = index
				//切换header跳转第一个模块路由
				let firstPath
				if(this.headerList[index].children[0].href) {
					firstPath = this.headerList[index].children[0].href
				} else {
					firstPath = this.headerList[index].children[0].children[0].href
				}
				this.$router.push({path: firstPath})

				this.$emit('activeMenu',this.$store.state.app.activeMenu)
				// this.$emit("on-change", this.$store.state.app.activeMenu);
				this.activeName = item.name
			},
		},
		created(){
			//进入页面时不执行computed，如下便执行(原因尚不明)
			let activeMenu = this.$store.state.app.activeMenu
			this.headerList.forEach((item,index) => {
				if(index == activeMenu){
					this.activeName = item.name
				}
			});
		},
	}
</script>

<style lang="scss">
	.header-height{
		z-index:999;
		height:60px;
		background:#515a6e;
		overflow:hidden;
		// margin-bottom:5px;
		.header-list{
			position:relative;
			overflow:hidden;
			float:left;
			.header-list-title{
				cursor:pointer;
				height: 100%;
			    float: left;
			    line-height: 60px;
			    text-align: center;
			    width: 100px;
		    	color: #fff;
			    span{
			    	font-family: '时尚中黑简体 Bold', '时尚中黑简体';
		    	    font-weight: 700;
		    	    font-style: normal;
		    	    font-size: 14px;
		    	    // #fff
			    }
			}
			.title-active{
				color:#fff;
				background:#808695;
			}
			// .header-list-background{
			// 	height:60px;
			// 	width: 100px;
			//     background-color: rgba(0, 179, 140, 1);
			//     position:absolute;
			// }
		}
	}
</style>