/*
 * @Author: 张小闹
 * @Date: 2020-05-08 09:06:45
 * @LastEditors: 逸帆
 * @LastEditTime: 2020-12-15 15:41:02
 * @FilePath: \super_system\src\utils\lib.js
 */
let url, name

name = 'afterSale_token';
if (window.location.href.includes('localhost') || window.location.href.includes('192.168.')) {
	url = 'https://cosmos.yundang.net/api'
} else {
	url = '/api'
}

let data = {
	url, name
}

export default data
