<template>
	<div class="drop-menu" :style="{ background: background }" v-if="headerList.length != 0">
		<template v-for="(item,index) in headerList[activeMenu].children">
			<div style="text-center">
		    	<Dropdown placement="right-start" @on-click="dropdown">
		    		<div class="menu-icon">
			        	<Icon :type="item.icon" :style="{ color: color }" size="22"></Icon>
		    		</div>
			        <DropdownMenu slot="list" style="width: 200px;">
			        	<DropdownItem v-if="item.children.length != 0" v-for="(items,idx) in item.children" :key="idx" :name="items.href">
			        		<Icon v-if="items.icon" :type="items.icon"></Icon>
			        		<span style="padding-left:10px">{{items.name}}</span>
			        	</DropdownItem>
			        	<DropdownItem v-if="item.children.length == 0" :name="item.href">
			        		<Icon v-if="item.icom" :type="item.icon"></Icon>
			        		<span style="padding-left:10px">{{item.name}}</span>
			        	</DropdownItem>
			        </DropdownMenu>
			    </Dropdown>
			</div>
		</template>
	</div>
</template>

<script>
	// import axios from 'Assets/js/axios.js'
	// import { mainHome } from '@/views/home/router/router'
	import Qs from 'qs'
	export default{
		props: {
			headerList: Array,
			activeMenu: Number,
			theme:String
		},
		data(){
			return{
				background:'#495060',
				color:'rgba(255,255,255,.7)',
				pageOpenedList:{}
			}
		},
		//已成功挂载，相当ready()
		created(){
           this.background = (this.theme == 'light') ? '#fff' : '#495060'
           this.color = (this.theme == 'light') ? '#495060' : 'rgba(255,255,255,.7)'
		},
		//相关操作事件
		methods: {
			//路由跳转简单封装
			routerPush(active,item) {
				if(active == item.title) {
					// this.pageOpenedList.title = item.title
					// this.pageOpenedList.path = item.path
					this.$router.push({name: item.name})
					this.$emit('on-change',item.name)
				}
			},
			// 点击事件
			dropdown(active){
				this.$router.push({name: active})
				//使侧边路由高亮与当前路由一致
				// this.headerList[this.activeMenu].activeRouter = active

				// let pageOpenedList
				// let allRouteList = this.$router.options.routes[1].children
				// allRouteList.forEach(item => {
				//   if(item.path == active) {
				//     pageOpenedList = item
				//   }
				// })
				// this.$store.commit('increateTag', pageOpenedList)
			}
		}
	}
</script>
<style lang="scss">
	.drop-menu{
		// background: #495060;
		height:100%;
		float:left;
		width:100%;
		// border:1px solid #dddee1
	}
	.menu-icon{
		width:70px;
		padding:10px 0;
		cursor:pointer;
		text-align: center;
		margin-left: -10px;
		// .drop-icon{
		// 	color: rgba(255,255,255,.7);
		// }
	}
</style>