const title = {
    title: '超级后台',
    logo: '',
    info: {
        title: '',
        list: [
        //   '这个是中台系统',
        //   '这个真的是中台系统',
        ]
    },
}

export default { 
    title 
}