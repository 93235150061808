import { render, staticRenderFns } from "./tags-page.vue?vue&type=template&id=61dc5886&scoped=true"
import script from "./tags-page.vue?vue&type=script&lang=js"
export * from "./tags-page.vue?vue&type=script&lang=js"
import style0 from "./tags-page.vue?vue&type=style&index=0&id=61dc5886&prod&lang=scss"
import style1 from "./tags-page.vue?vue&type=style&index=1&id=61dc5886&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_webpack@5.92.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61dc5886",
  null
  
)

export default component.exports