<template>
  <div class="index" ref="headerMenu">
    <div class="layout">
      <Layout>
        <Header>
          <header-menu :header-list="headerNewData" :change-module="changeModule">
            <div slot="left" class="header-left-title" :style="{ color: color }">
              <span>超级后台</span>
            </div>
            <div slot="right" class="header-right-title">
              <div class="right-button">
                <a href="http://helpcenter.djson.cn/" target="_blank">
                  <img src="../assets/images/manual.png" height="25" width="25" alt />
                </a>
              </div>
              <div class="right-button">
                <Dropdown @on-click="handleLoginName">
                  <span>
                    {{ userMessage.name }}
                    <Icon type="ios-arrow-down"></Icon>
                  </span>
                  <DropdownMenu slot="list">
                    <DropdownItem name="outLogin">退出登录</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </header-menu>
        </Header>
        <Content :style="{ background: '#69c2a3', minHeight: '500px', backgroundImage: 'linear-gradient(45deg, #e6a23c, #e8f6f9, #d5efed, #b7e4da, #8fd4c1, #69c2a3, #49b17f, #f56c6c, #157f3c)'}">
          <div class="moduleList">
            <div v-for="(item,index) of moduleList" :key="index" @click="getNodule(item)" class="listColor">
              <div>
                <img src='../assets/images/listone.jpg' v-if="index == 0" alt class="imgStyle"/>
                <img src='../assets/images/listtwo.jpg' v-if="index == 1" alt class="imgStyle"/>
                <img src='../assets/images/listthree.jpg' v-if="index == 2" alt class="imgStyle"/>
                <img src='../assets/images/listfive.jpg' v-if="index == 3" alt class="imgStyle"/>
                <!-- <img src='../assets/images/listsix.jpg' v-if="index == 4" alt class="imgStyle"/> -->
                <img src='' v-if="index == 5" alt class="imgStyle"/>
              </div>
              <div style="padding: 10px 0" v-if="item.name != '市场发展商'">{{item.name}}</div>
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  </div>
</template>

<script>
import Qs from "qs";
import store from "../store/index.js";
import tagsPage from "@/components/tags-page/tags-page.vue";
import headerMenu from "@/components/header-menu/header-menu.vue";
import { mapMutations } from "vuex";

export default {
  name: "Home",
  store,
  components: {
    tagsPage,
    headerMenu
  },

  data() {
    return {
      moduleList: [],
      userMessage: {
        name: ''
      },
      theme: "dark",
      menuIndividual: [],
      shrink: false,
      changeModule: true,
      headerNewData: [],
      clientX: 0,
      clientY: 0,
      flag: false,
      idx: 0
    };
  },

  watch: {},

  computed: {
    bgColor() {
      return this.theme === "dark" ? "#495060" : "#fff";
    },
    color() {
      return this.theme === "dark" ? "rgba(255,255,255,.7)" : "#495060";
    }
  },

  //已成功挂载，相当ready()
  created() {
    document.title = '超级后台（档口网）'
    localStorage.setItem('aftersale_code','')
    let userMessage = JSON.parse(localStorage.getItem("afterSaleList"));
    if(userMessage) {
      this.userMessage = userMessage
    }
    this.loadData();
  },

  //相关操作事件
  methods: {
		// 点击跳转
		getNodule(item) {
      localStorage.setItem('aftersale_code',item.code)
      this.$store.state.app.routerPathList = ['/','master','error']
      // console.log(this.$store.state.app.routerPathList, "s")
      setTimeout(() => {
        this.$router.push({path: '/'});
      }, 500);
		},

    // 获取权限列表
    loadData() {
      this.axios.ajax({
        url: "/auth/modules",
        success: res => {
          let userMessage = JSON.parse(localStorage.getItem("afterSaleList"));
          if(userMessage) {
            this.userMessage = userMessage
          }
          if (res.status == 0) {
            this.moduleList = res.data;
            localStorage.setItem('aftersale_codeList',JSON.stringify(res.data))
            // 如果只有一个权限列表就直接跳过去
            if(this.moduleList.length == 1){
              this.getNodule(this.moduleList[0])
            }
          } else {
            this.$Message.error(res.msg);
          }
        }
      });
    },

    //顶部导航右侧下拉框
    handleLoginName(name) {
      //退出登录
      if (name == "outLogin") {
        this.delCookie("afterSale_token");
        localStorage.removeItem("afterSaleList");
        this.$router.push({ name: "login" });
      }
    },

    delCookie(name) {
      var exp = new Date();
      exp.setTime(exp.getTime() - 1);
      var cval = this.getCookie(name);
      if (cval != null)
        document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
    },

    getCookie(name) {
      var arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
      else return null;
    }
  }
};
</script>

<style lang="scss">
.moduleList{
	display: flex;
  height: 100%;
	justify-content: center;
  align-items: center;
	text-align: center;
	padding: 20px 0;
}
.index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgb(244, 244, 244);
}
.top-title {
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}
.menu {
  height: 100%;
  transition: width 0.3s;
}
.content {
  overflow: scroll;
  padding: 10px;
  padding-right: 5px;
  height: 100%;
  .tags {
    z-index: 999;
    width: 100%;
    background: #fff;
    padding: 3px 5px;
    height: 44px;
  }
}
.router-box {
  height: 100%;
  overflow-x: hidden;
}

.header-left-title {
  float: left;
  padding: 0 60px 0 20px;
  span {
    line-height: 60px;
    font-family: "华文琥珀 Regular", "华文琥珀";
    // font-weight: 800;
    font-style: normal;
    font-size: 20px;
    color: #fff;
    text-align: left;
  }
  .header-button {
    // height:50px;
    display: inline-block;
    cursor: pointer;
    padding: 0px 5px;
    .btn-icon {
      transition: all 0.2s ease;
      border: 0px;
      font-size: 20px;
      color: #fff;
    }
  }
  .ivu-icon {
    display: block;
  }
}
.header-right-title {
  float: right;
  color: #fff;
  cursor: pointer;
  line-height: 60px;
  padding-right: 40px;
  .right-button {
    display: inline-block;
    margin-left: 20px;
    img {
      vertical-align: middle;
    }
    a {
      height: 25px;
      display: inline-block;
      line-height: 25px;
    }
  }
}
.demo-tabs-style > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab {
  border-radius: 0;
  background: #fff;
}
.demo-tabs-style
  > .ivu-tabs.ivu-tabs-card
  > .ivu-tabs-bar
  .ivu-tabs-tab-active {
  border-top: 1px solid #3399ff;
}
.demo-tabs-style
  > .ivu-tabs.ivu-tabs-card
  > .ivu-tabs-bar
  .ivu-tabs-tab-active:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #3399ff;
  position: absolute;
  top: 0;
  left: 0;
}
.demo-ul {
  position: absolute;
  z-index: 9999;
  background-color: white;
  padding: 5px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  a {
    text-decoration: none;
    color: black;
    font-size: 12px;
  }
  .demo-li {
    margin: 0;
    line-height: normal;
    padding: 7px 16px;
    clear: both;
    color: #515a6e;
    font-size: 12px !important;
    white-space: nowrap;
    list-style: none;
    cursor: pointer;
    -webkit-transition: background 0.2s ease-in-out;
    transition: background 0.2s ease-in-out;
  }
  .demo-li:hover {
    background-color: #f5f5f5;
  }
}
//取消头部默认样式
.ivu-layout-header {
  padding: 0 0px;
  height: auto;
  line-height: auto;
}
.ivu-layout {
  overflow: hidden !important;
}
.ivu-layout-content {
  height: 100%;
}
.listColor{
  margin: 0 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 8px #ffffff;
  transition: all 0.5s;
  color: #909399;
  font-weight: bold;
  font-size: 15px;
  background: #fff;
  border-radius: 10px;
}
.listColor:hover{
  margin-top: 20px;
  color: #dc341a;
}
.imgStyle{
  width: 160px;
  height: 140px;
  border-radius: 10px;
}
</style>
