<template>
	<div class="login">
		<div class="loginMiddle">
			<div class="loginBar">
				<p class="username">
					<label>姓名/手机号:</label>
					<input type="text" placeholder="请输入账户" name="username" id="username" v-model="username">
				</p>
				<p class="password">
					<label>密码:</label>
					<input type="password" placeholder="请输入密码" name="password" id="password" v-model="password" @keyup.enter="validate">
				</p>
			</div>
			<div class="submit" @click='validate'>登录</div>
		</div>
	</div>
</template>
<script>
	// import axios from 'Assets/js/axios.js'
	export default {
		name:'Login',
		data(){
			return{
				username:'',
				password:''
			}
		},

		created() {
			localStorage.setItem('aftersale_code','')
			this.axios.ajax({ // 检测登录状态
				url: '/auth/token/check',
				success: res => {
					if(res.data.is_valid) {
						if(!localStorage.getItem('afterSaleList')) {
							axios.ajax({
								type: 'POST',
								url: '/auth/user',
								success: res => {
									// this.$router.push({ path: '/'})
									localStorage.setItem('afterSaleList', JSON.stringify(res.data));
									this.$router.push('/master')
								}
							})
						} else {
							this.$router.push('/master')
						}
					}
				}
			})
		},
		methods:{
			validate(){
				if(!this.username || !this.password) {
						this.$notify({
							title: 'warn',
							message: '请输入完整的用户信息',
							type: 'warn'
						});
					return
				}
				var date = new Date();
				if(this.checked == true) {
					date.setTime((new Date(new Date().setHours(0, 0, 0, 0))/1000 + 24 * 7 * 60 * 60)*1000);
				} else {
					date.setTime((new Date(new Date().setHours(0, 0, 0, 0))/1000 + 24 * 3 * 60 * 60)*1000);
				}
				this.axios.ajax({
					url: '/auth/token/get',
					data: {
						username: this.username,
						password: this.password
					},
					success: res => {
						let type
						if(res.status === 0) {
							type = 'success'
							document.cookie ="afterSale_token=" + res.data.access_token + ";expires=" + date.toGMTString();
							setTimeout(() => { this.$router.push({ path: '/master'}) },500)

							this.axios.ajax({
								type: 'POST',
								url: '/auth/user',
								success: res => {
									localStorage.setItem('afterSaleList', JSON.stringify(res.data));
								}
							})
						} else {
							type = 'error'
						}
						this.$notify({
							title: '提示',
							message: res.msg,
							type: type
						});
					}
				})
			},
		}
	}
</script>
<style lang="scss" scoped>
	input:focus {
		outline:none;
	}
	input:-webkit-autofill{
		-webkit-box-shadow: 0 0 0px 1000px white inset;
		-webkit-text-fill-border:thin solid  #fff!important;
		-webkit-text-fill-color:666;
	}
	.login {
		width: 100%;
		height: calc(100vh);
		background-image: url('../assets/images/afterLogin.jpg');
		background-size: cover;
		background-position: center;
		overflow: hidden;
	}
	.loginMiddle {
		width: calc(30vw);
		background:rgba(244,244,244,.9);
		height: 270px;
		padding-top: 50px;
		border-radius: 5px;
		border: 1px solid #ddd;
		box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
		margin: calc(30vh) calc(35vw);
		text-align: center;
		.loginBar {
			p {
				padding: 10px 0;
			}
			input {
				border: 1px solid #ccc;
				padding-left: 6px;
				border-radius: 2px;
				width: 230px;
				height: 28px;
			}
			label {
				display: inline-block;
				width: 100px;
				text-align: right;
				padding-right: 10px;
			}
		}
		.submit {
			width: 160px;
			height: 40px;
			background: #108EE9;
			color: #fff;
			line-height: 40px;
			border-radius: 4px;
			margin: 40px auto;
			cursor: pointer;
		}
	}
</style>