/*
 * @Author: 张小闹
 * @Date: 2020-06-19 14:44:58
 * @LastEditors: 逸帆
 * @LastEditTime: 2020-08-31 15:47:17
 * @FilePath: \super_system\src\store\index.js
 */ 
import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app';
import user from './modules/user';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        //
        code: '',
        menulist:[],
        imageModal: false,
        imageId:'',
        imageId:'',
        tpl:'',
        dataUrl:"",
        moblieUrl:"",
        imageUrl:"",
        sampleUp:false,//
        imageTpl:'',
        waterModal:false,
        toggleModal:false,
        formData: "",
        imgFlag: false,
        addGoodsList:[
            {
                image: "",
                price: "",
                id: 1,
                pid: ""
            },
            {
                image: "",
                price: "",
                id: 2,
                pid: ""
            },
            {
                image: "",
                price: "",
                id: 3,
                pid: ""
            }
        ],

    },
    mutations: {
        //
    },
    actions: {

    },
    modules: {
        app,
        user,
    }
});

export default store;
